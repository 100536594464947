// src/routes.js

import React from "react";
import { Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import FHE from "./pages/FHE";
import DEX from "./pages/DEX";

const AppRoutes = () => (
  <Routes>
    <Route path="/" element={<HomePage />} />
    <Route path="/FHE" element={<FHE />} />
    <Route path="/DEX" element={<DEX />} />
  </Routes>
);

export default AppRoutes;
