import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { supabase } from "../../supabase/supabaseClient";

const PageTracker = ({ isAuthenticated }) => {
  const location = useLocation(); // Tracks the current page - hook
  const startTimeRef = useRef(Date.now()); // Ref to store the start time - ref is used to store mutable values that will NOT trigger a rerender when the value is updated
  const intervalRef = useRef(null); // Ref to store the interval ID - holds the reference to the setInterval ID, so it can be cleared when the component unmounts or the user navigates away
  const isLoggingRef = useRef(true); // Ref to track if logging is active or paused - logging is paused if the user navigates away
  const inactivityTimeoutRef = useRef(null); // Ref to store inactivity timeout ID
  const intervalValue = 20 * 1000; // write every 20s
  let hideTimeoutRef = useRef(null);

  let initialTime = 90000; // 1 minute 20 seconds of no interaction pauses logging
  let remainingTime = initialTime;
  const decrementInterval = 1000; // Decrement every 1 second

  useEffect(() => {
    if (isAuthenticated) {
      const userEmail = localStorage.getItem("user_email");

      const logTimeSpent = async () => {
        if (userEmail && startTimeRef.current && isLoggingRef.current) {
          const currentTime = Date.now();
          const timeSpentSinceLastLog = currentTime - startTimeRef.current;
          startTimeRef.current = currentTime; // Reset start time for the next interval

          const now = new Date();
          const timestamp = now.toISOString();

          // Write time since last log to the database
          const { error } = await supabase.from("page_views").insert([
            {
              user_email: userEmail,
              page: location.pathname,
              time_spent: timeSpentSinceLastLog,
              timestamp: timestamp,
            },
          ]);

          if (error) {
            console.error("Error recording page view:", error);
          }
          // console.log(
          //   "logged:",
          //   timeSpentSinceLastLog,
          //   "remaining:",
          //   remainingTime
          // );
        }
      };

      intervalRef.current = setInterval(logTimeSpent, intervalValue); // Run every 20 seconds (1 minute)

      const stopLoggingAndClearInterval = async () => {
        isLoggingRef.current = false;

        if (intervalRef.current) {
          clearInterval(intervalRef.current); // Clear the interval when the component unmounts
        }

        // Final logging of remaining time before navigation - runs right before the user switches routes
        const now = new Date();
        const finalTimeSpent = now - startTimeRef.current;
        const timestamp = now.toISOString();

        // write to db
        const { error } = await supabase.from("page_views").insert([
          {
            user_email: userEmail,
            page: location.pathname,
            time_spent: finalTimeSpent,
            timestamp: timestamp,
          },
        ]);

        if (error) {
          console.error("cleanup function error:", error);
        }
      };

      // Function to reset inactivity timer
      const resetInactivityTimer = () => {
        // Clear any existing timeout
        if (inactivityTimeoutRef.current) {
          clearInterval(inactivityTimeoutRef.current);
        }

        if (!isLoggingRef.current) {
          // user returned from idle state
          startTimeRef.current = Date.now();
          isLoggingRef.current = true;
        }

        remainingTime = initialTime;

        inactivityTimeoutRef.current = setInterval(() => {
          remainingTime -= decrementInterval;

          // When remaining time reaches 0, stop logging and clear the interval
          if (remainingTime <= 0) {
            clearInterval(inactivityTimeoutRef.current);
            inactivityTimeoutRef.current = null;
            isLoggingRef.current = false;
          }
        }, decrementInterval);
      };

      // Handle mouse, scroll, and touch events
      const handleUserActivity = () => {
        resetInactivityTimer();
      };

      // Attach event listeners for mouse, scroll, and touch activity
      document.addEventListener("mousemove", handleUserActivity);

      document.addEventListener("touchstart", handleUserActivity, {
        passive: true,
      }); // Mobile touch events

      // Handle Visibility Changes (if the user switches to a new browser tab)
      const handleVisibilityChange = async () => {
        const currentTime = Date.now();

        if (document.visibilityState === "hidden") {
          isLoggingRef.current = false;

          const timeSpentBeforeHidden = currentTime - startTimeRef.current;

          hideTimeoutRef.current = setTimeout(async () => {
            const now = new Date();
            const timestamp = now.toISOString();

            const { error } = await supabase.from("page_views").insert([
              {
                user_email: userEmail,
                page: location.pathname,
                time_spent: timeSpentBeforeHidden,
                timestamp: timestamp,
              },
            ]);

            // if (error) {
            //   console.log("error logging time on tab hide:", error);
            // }
          }, 1000);
        } else if (document.visibilityState === "visible") {
          // Resume logging when the page becomes visible again
          isLoggingRef.current = true;

          if (hideTimeoutRef.current) {
            clearTimeout(hideTimeoutRef.current); // Cancel the timeout if the user returns quickly
          }

          if (intervalRef.current) {
            clearInterval(intervalRef.current);
          }

          startTimeRef.current = Date.now();
          intervalRef.current = setInterval(logTimeSpent, intervalValue);

          startTimeRef.current = Date.now(); // Reset the start time (might be wrong)
        }
      };

      document.addEventListener("visibilitychange", handleVisibilityChange);

      // Cleanup function: clear the interval and log the remaining time
      return async () => {
        await stopLoggingAndClearInterval();
        // console.log("cleanup event listeners");
        // Cleanup the event listener
        document.removeEventListener(
          "visibilitychange",
          handleVisibilityChange
        );
        document.removeEventListener("mousemove", handleUserActivity);
        document.removeEventListener("touchstart", handleUserActivity);
      };
    }
  }, [isAuthenticated, location]);

  return null;
};

export default PageTracker;
