import React from "react";
import { AppBar, Toolbar, Typography, Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

const Navbar = ({ backgroundColor: useBackgroundColor }) => {
  return (
    <AppBar position="fixed" sx={{ backgroundColor: useBackgroundColor }}>
      <Toolbar>
        <Typography variant="h6">
          <Link
            component={RouterLink}
            to="/"
            sx={{
              color: "white", // Explicitly set color to white
              textDecoration: "none", // Remove underline
              "&:visited": {
                color: "white", // Ensure visited links stay white
              },
            }}
          >
            nebula systems
          </Link>
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
