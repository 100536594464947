// src/components/Auth/LoginDialog.js

import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  DialogActions,
  Typography,
  Stack,
  Box,
} from "@mui/material";
import { supabase } from "../../supabase/supabaseClient";
import useMediaQuery from "@mui/material/useMediaQuery";

const SHARED_PASSCODE = "fhedex"; // Replace with your passcode

const validateEmail = (email) => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(email);
};

const LoginDialog = ({ open, onClose, onLoginSuccess }) => {
  const [email, setEmail] = useState("");
  const [passcode, setPasscode] = useState("");
  const [error, setError] = useState(false);
  const [isEmailError, setIsEmailError] = useState("");
  const [errorMessage, setErrorMessage] = useState(""); // String for the error message
  const isSmallScreen = useMediaQuery("(max-width:900px)");

  const handleEmail = (event) => {
    if (!validateEmail(email)) {
      setError(true);
      setErrorMessage("Invalid email");
    } else {
      setError(false);
      handleLogin();
    }
  };

  const handleLogin = async () => {
    if (passcode !== SHARED_PASSCODE) {
      setErrorMessage("Invalid passcode.");
      return;
    }

    try {
      // Check or insert user in Supabase
      const { data, error } = await supabase
        .from("users")
        .select("*")
        .eq("email", email);

      if (error) throw error;

      if (data.length === 0) {
        const { error: insertError } = await supabase
          .from("users")
          .insert([{ email }]);
        if (insertError) throw insertError;
      }

      // Store email in localStorage or context
      localStorage.setItem("user_email", email);
      onLoginSuccess();
    } catch (err) {
      setError("Login failed. Please try again.");
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleLogin(); // Trigger login on Enter key
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => {}} // Disable onClose to prevent dismissal without login
      disableEscapeKeyDown={true}
      sx={{ backgroundColor: "black" }}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle
        sx={{
          backgroundColor: "#111111",
          borderTop: "1.5px solid",
          borderLeft: "1.5px solid",
          borderRight: "1.5px solid",
          borderBottom: "none",
          borderColor: "#272727",
          padding: { xs: "16px", sm: "16px", md: "24px", lg: "24px" },
        }}
      >
        <Typography
          sx={{
            fontSize: { xs: "16px", sm: "16px", md: "24px", lg: "24px" },
            fontWeight: { xs: "600", sm: "500", md: "500", lg: "500" },
          }}
          color="white"
        >
          Share your email to continue
        </Typography>
        <Box height="8px"></Box>
      </DialogTitle>
      <DialogContent
        sx={{
          backgroundColor: "#111111",
          borderTop: "none",
          borderRight: "1.5px solid",
          borderLeft: "1.5px solid",
          borderBottom: "none",
          borderColor: "#272727",
          paddingLeft: { xs: "16px", sm: "16px", md: "24px", lg: "24px" },
          paddingRight: { xs: "16px", sm: "16px", md: "24px", lg: "24px" },
        }}
      >
        <Stack spacing={1} sx={{ marginBottom: 1 }}>
          <Typography variant="body2" color="#CFCFCF">
            Email
          </Typography>
          <TextField
            placeholder="satoshi@nebula.systems"
            size={isSmallScreen ? "small" : "medium"} // Conditionally render the size
            variant="outlined"
            type="email"
            fullWidth
            margin="normal"
            value={email}
            error={errorMessage}
            required
            onChange={(e) => setEmail(e.target.value)}
            autoFocus
          />
        </Stack>
        <Stack spacing={1}>
          <Typography variant="body2" color="#CFCFCF">
            Passcode
          </Typography>
          <TextField
            placeholder="passcode"
            size={isSmallScreen ? "small" : "medium"} // Conditionally render the size
            type="password"
            fullWidth
            margin="normal"
            value={passcode}
            onKeyDown={handleKeyDown}
            onChange={(e) => setPasscode(e.target.value)}
          />
        </Stack>
        {errorMessage && (
          <Typography color="error" variant="body2">
            {errorMessage} {/* Display error message */}
          </Typography>
        )}
      </DialogContent>
      <DialogActions
        sx={{
          backgroundColor: "#111111",
          borderRight: "1.5px solid",
          borderLeft: "1.5px solid",
          borderBottom: "1.5px solid",
          borderTop: "none",
          borderColor: "#272727",
          paddingLeft: { xs: "16px", sm: "16px", md: "24px", lg: "24px" },
          paddingRight: { xs: "16px", sm: "16px", md: "24px", lg: "24px" },
          paddingBottom: { xs: "16px", sm: "16px", md: "24px", lg: "24px" },
        }}
      >
        <Button
          onClick={handleEmail}
          variant="contained"
          color="black"
          fullWidth
          sx={{
            fontSize: { xs: "16px", sm: "20px", md: "20px", lg: "20px" },
            fontWeight: { xs: "600px", sm: "600px", md: "500px", lg: "500px" },
            height: { xs: "44px", sm: "44px", md: "52px", lg: "52px" },
          }}
        >
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LoginDialog;
