import React from "react";
import { Box, Typography, Stack, Button } from "@mui/material";
import Navbar from "../components/layout/Navbar";
import { ArrowBack, ArrowOutward } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import Spline from "@splinetool/react-spline";

const DEX = () => {
  const navigate = useNavigate();

  const handleNavigationHome = () => {
    navigate("/"); // Navigate to the "/" route
  };

  const handleNavigationFHE = () => {
    navigate("/FHE");
  };

  const handleContact = () => {
    window.open("https://www.t.me/paxamericana", "_blank"); // Opens the link in a new tab
  };

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: "100dvh", // Full height of viewport
        overflow: "auto",
        backgroundColor: "black", // Set background color to black for the whole page
      }}
    >
      <Navbar
        backgroundColor={"black"}
        sx={{
          position: "sticky",
          top: 0,
          left: 0,
          width: "100%",
          boxShadow: "none",
          zIndex: 2,
        }}
      />

      <Stack>
        <Box
          sx={{
            height: "40vh",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            alignItems: "center",
            borderBottom: "1px solid",
            borderColor: "#272727",
            backgroundColor: "transparent", // Transparent so particles show
            zIndex: 1,
          }}
        >
          {/* Particles Background Scoped to 40vh Box */}
          <Box
            sx={{
              position: "absolute",
              width: "100%",
              height: "100%",
              top: 0,
              left: 0,
              zIndex: -1, // Particles stay behind the content
            }}
          >
            <Spline
              scene="https://prod.spline.design/AR2OsVSryukNaSwZ/scene.splinecode"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
            />
          </Box>
          <Stack
            spacing={1}
            sx={{
              width: "100%",
              maxWidth: "1064px",
              alignItems: "start",
              paddingLeft: "24px",
              paddingRight: "24px",
              paddingBottom: { xs: "24px", sm: "24px", md: "24px", lg: "24px" },
              zIndex: 2,
            }}
          >
            <Button
              onClick={handleNavigationHome}
              variant="text"
              startIcon={
                <ArrowBack
                  sx={{
                    fontSize: "16px",
                    "&:hover": {
                      color: "#FFF1D5", // Text color when hovered
                      textShadow:
                        "0 0 1px #FFF1D5, 0 0 2px #FFF1D5, 0 0 8px #FFF1D5", // Glowing effect
                    },
                  }}
                />
              }
              sx={{
                fontFamily: "Electrolize, Arial, sans-serif",
                color: "#DCDCDC",
                fontSize: { xs: "16px", sm: "20px", md: "20px", lg: "20px" },
                fontWeight: "500",
                "@media (hover: hover)": {
                  "&:hover": {
                    color: "#FFF1D5", // Text color when hovered
                    textShadow:
                      "0 0 1px #FFF1D5, 0 0 2px #FFF1D5, 0 0 8px #FFF1D5", // Glowing effect
                  },
                },
              }}
            >
              Home
            </Button>
            <Typography
              gutterBottom
              color="white"
              sx={{
                typography: { xs: "h5", sm: "h4", md: "h4", lg: "h4" },
              }}
            >
              DEX DEX Revolution
            </Typography>
            <Typography
              sx={{
                fontFamily: "Electrolize, Arial, sans-serif",
                color: "#DCDCDC",
                fontSize: { xs: "16px", sm: "20px", md: "20px", lg: "20px" },
                fontWeight: "500",
              }}
            >
              2 min read
            </Typography>
          </Stack>
        </Box>

        <Box>
          {/* Inner box for content */}
          <Stack
            spacing={4}
            sx={{
              maxWidth: "1064px",
              margin: "0 auto",
              padding: 3,
              paddingY: { xs: "24px", sm: "24px", md: "36px", lg: "36px" },
              backgroundColor: "black", // Solid black for the main content area
              zIndex: 1, // Content is above particles, but no particles here
            }}
          >
            <Typography
              color="white"
              sx={{
                typography: {
                  xs: "body2",
                  sm: "body1",
                  md: "body1",
                  lg: "body1",
                },
                "& ul": {
                  paddingLeft: "16px", // Set indent for <ul>
                  marginTop: "24px", // Optional: spacing before each list item
                  marginBottom: "24px", // Optional: spacing after each list item
                },
                "& br": {
                  lineHeight: "24px", // Custom spacing for line breaks
                },
                "& strong": {
                  fontWeight: 800, // Set font weight to bold
                },
              }}
            >
              Decentralized exchange (DEX) protocols have the potential to
              dramatically improve the efficiency of markets. To date, however,
              DEX protocols have been unable to compete with, let alone
              outcompete, centralized exchanges (CEXs) on both sides of the
              market. <br />
              <br />
              <span className="responsive-subtitle"> Rocks in the Road</span>
              <br /> <br />
              Because traders' pending transactions on DEXs are public and
              arbitrarily sequenced, they are subject to lose the maximal
              extractable value (MEV) every single time. Because positions are
              public, they are subject to being hunted. MEV and position hunting
              on DEXs are so pernicious that nearly all large players prefer
              custodying their assets on a CEX despite being unable to verify
              whether the CEX is frontrunning their trades, hunting their
              positions or even solvent. <br /> <br /> The situation is just as
              bad for market makers, who face fees to update orders and toxic
              flow (i.e., latency arbitrage). On CEXs, market makers update
              their orders for free with immense frequency to constantly reflect
              the latest market conditions and thereby avoid toxic flow. On
              DEXs, however, market makers pay a fee and suffer MEV every time
              they cancel and place new orders, which forces them to incur the
              maximum toxic flow in every block. The losses from fees and toxic
              flow are passed onto traders in the form of wider spreads.
              <br />
              <br />
              Therefore, DEXs have not been able to offer a user experience
              competitive with CEXs.
              <br />
              <br />
              <span className="responsive-subtitle"> Dynamite</span>
              <br /> <br /> Thanks to state of the art cryptography, including
              fully homomorphic encryption, functional encryption and
              indistinguishability obfuscation, a new paradigm is on the
              horizon. <br /> <br /> Nebula is pioneering a new type of DEX
              that:
              <ul>
                • protects traders from having their orders frontrun and keeps
                their positions private, thereby securing them from MEV and
                position hunting
              </ul>
              <ul>
                • enables market makers to post entire strategies in an
                encrypted form such that their orders update privately,
                automatically and atomically at the time trades are cleared
                against them, thereby removing their exposure to excessive fees,
                MEV and ultimately toxic flow
              </ul>{" "}
              A new era of exchange is here. In the coming months, we will
              publish our work and open source our solution
            </Typography>

            <Stack direction="row" spacing={2}>
              <Button
                onClick={handleNavigationFHE}
                variant="contained"
                color="black"
                sx={{
                  fontFamily: "Electrolize, Arial, sans-serif",
                  fontSize: { xs: "16px", sm: "20px", md: "20px", lg: "20px" },
                  height: { xs: "44px", sm: "52px", md: "52px", lg: "52px" },
                  color: "white",
                  backgroundColor: "#1B1B1B",
                  border: "1px solid",
                  borderColor: "#494949",
                  "&:hover": {
                    backgroundColor: "rgba(27, 27, 27, 0.6)", // Change opacity on hover
                    color: "rgba(255, 255, 255, 0.8)", // Change text color opacity on hover
                  },
                }}
              >
                read about FHE
              </Button>

              <Button
                onClick={handleContact}
                variant="contained"
                endIcon={<ArrowOutward />}
                sx={{
                  fontFamily: "Electrolize, Arial, sans-serif",
                  fontSize: { xs: "16px", sm: "20px", md: "20px", lg: "20px" },
                  height: { xs: "44px", sm: "52px", md: "52px", lg: "52px" },
                  color: "white",
                  backgroundColor: "black",
                  border: "1px solid",
                  borderColor: "#494949",
                  "&:hover": {
                    backgroundColor: "black",
                    color: "rgba(255, 255, 255, 0.8)", // Change text color opacity on hover
                    borderColor: "rgba(73, 73, 73, 0.8)", // Change border color opacity on hover
                  },
                }}
              >
                contact us
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};

export default DEX;
