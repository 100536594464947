import React from "react";
import { Box, Typography, Stack, Button } from "@mui/material";
import Navbar from "../components/layout/Navbar";
import { ArrowBack, ArrowOutward } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import Spline from "@splinetool/react-spline";

const FHE = () => {
  const navigate = useNavigate(); // Initialize useNavigate

  const handleNavigationHome = () => {
    navigate("/"); // Navigate to the "/" route
  };

  const handleNavigationDEX = () => {
    navigate("/DEX"); // Navigate to the DEX page
  };

  const handleContact = () => {
    window.open("https://www.t.me/paxamericana", "_blank"); // Opens the link in a new tab
  };

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: "100dvh", // Full height of viewport
        overflow: "auto",
        backgroundColor: "black", // Set background color to black for the whole page
      }}
    >
      {/* Navbar */}
      <Navbar
        backgroundColor={"black"}
        sx={{
          position: "sticky",
          top: 0,
          left: 0,
          width: "100%",
          boxShadow: "none",
          zIndex: 2, // Ensure the navbar stays above the particles
        }}
      />

      <Stack>
        {/* Top section where particles should be visible */}
        <Box
          sx={{
            height: "40vh",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            alignItems: "center",
            borderBottom: "1px solid",
            borderColor: "#272727",
            backgroundColor: "transparent", // Transparent so particles show
            zIndex: 1,
          }}
        >
          {/* Particles Background Scoped to 40vh Box */}
          <Box
            sx={{
              position: "absolute",
              width: "100%",
              height: "100%",
              top: 0,
              left: 0,
              zIndex: -1, // Particles stay behind the content
            }}
          >
            <Spline
              scene="https://prod.spline.design/AR2OsVSryukNaSwZ/scene.splinecode"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
            />
          </Box>

          <Stack
            spacing={1}
            sx={{
              width: "100%",
              maxWidth: "1064px",
              alignItems: "start",
              paddingLeft: "24px",
              paddingRight: "24px",
              paddingBottom: { xs: "24px", sm: "24px", md: "24px", lg: "24px" },
              zIndex: 2, // Ensure text is above the particles
            }}
          >
            <Button
              onClick={handleNavigationHome}
              variant="text"
              startIcon={
                <ArrowBack
                  sx={{
                    fontSize: "16px",
                    "&:hover": {
                      color: "#FFF1D5", // Text color when hovered
                      textShadow:
                        "0 0 1px #FFF1D5, 0 0 2px #FFF1D5, 0 0 8px #FFF1D5", // Glowing effect
                    },
                  }}
                />
              }
              sx={{
                fontFamily: "Electrolize, Arial, sans-serif",
                color: "#DCDCDC",
                fontSize: { xs: "16px", sm: "20px", md: "20px", lg: "20px" },
                fontWeight: "500",
                "@media (hover: hover)": {
                  "&:hover": {
                    color: "#FFF1D5", // Text color when hovered
                    textShadow:
                      "0 0 1px #FFF1D5, 0 0 2px #FFF1D5, 0 0 8px #FFF1D5", // Glowing effect
                  },
                },
              }}
            >
              Home
            </Button>
            <Typography
              gutterBottom
              color="white"
              sx={{
                typography: { xs: "h5", sm: "h4", md: "h4", lg: "h4" },
              }}
            >
              FHE is Coming Sooner Than You Think, But It Has a Dirty Secret
            </Typography>
            <Typography
              sx={{
                fontFamily: "Electrolize, Arial, sans-serif",
                color: "#DCDCDC",
                fontSize: { xs: "16px", sm: "20px", md: "20px", lg: "20px" },
                fontWeight: "500",
              }}
            >
              3 min read
            </Typography>
          </Stack>
        </Box>

        {/* Main content section with solid black background */}
        <Box>
          <Stack
            spacing={4}
            sx={{
              maxWidth: "1064px",
              margin: "0 auto",
              padding: 3,
              paddingY: { xs: "24px", sm: "24px", md: "36px", lg: "36px" },
              backgroundColor: "black", // Solid black for the main content area
              zIndex: 1, // Content is above particles, but no particles here
            }}
          >
            <Typography
              color="white"
              sx={{
                typography: {
                  xs: "body2",
                  sm: "body1",
                  md: "body1",
                  lg: "body1",
                },
                "& ul": {
                  paddingLeft: "16px",
                  marginTop: "24px",
                  marginBottom: "24px",
                },
                "& br": {
                  lineHeight: "24px",
                },
                "& strong": {
                  fontWeight: 800, // Set font weight to bold
                },
                "& blockquote": {
                  marginX: "8px",
                  fontWeight: 600,
                },
              }}
            >
              Fully Homomorphic Encryption (FHE) promises to transform the power
              of information-technologies and our standards for data security by
              allowing computations on encrypted data. <br /> <br /> Once
              implemented at scale,{" "}
              <strong>
                {" "}
                people will no longer be forced to expose their data to big
                companies and governments to receive personalized internet
                services.{" "}
              </strong>{" "}
              Instead, information will be modular, composable and free for its
              owners to use as they wish. A new era of data sovereignty is on
              the horizon in which individuals can:
              <br />
              <ul>• control and monetize access to their information </ul>
              <ul>
                • allow AI models to learn from their data without learning the
                data itself
              </ul>
              <ul>
                • choose which algorithms to interact with based on their
                preferences
              </ul>
              <ul>
                • opt into hyper-personalized online experiences that don’t
                compromise privacy
              </ul>
              <ul>
                • trust the security of their data, even in case of a hack
              </ul>
              FHE was only proven possible in 2009. Since then, a tremendous
              amount of work has been done by a number of companies to develop
              various working implementations. However, specialized cryptography
              hardware, which is just now becoming available, is required to
              make FHE computation competitive with regular computation. <br />{" "}
              <br /> <span className="responsive-subtitle"> Dirty Secret</span>
              <br /> <br />
              Despite the excitement about FHE’s potential to dramatically
              improve the quality and privacy of our lives, modern FHE schemes
              have an Achilles heel: all key pairs used to encrypt values in a
              computation are required to decrypt its output. In practice, this
              means that there can only be a single global key pair for systems
              where multiple parties are contributing encrypted data. <br />{" "}
              <br /> State of the art solutions for handling the global
              decryption key offer no privacy guarantees whatsoever in practice.
              Leading solutions use a threshold decryption protocol that splits
              up the key into pieces and requires some threshold of signatures
              to decrypt.
              <br />
              <br />
              <blockquote
                style={{
                  paddingLeft: "8px",
                }}
              >
                <span className="responsive-text">
                  {" "}
                  But there is no way to detect whether key holders collude
                  offline to view data without permission.{" "}
                </span>
              </blockquote>
              <br />
              Given the significant incentive to collude,{" "}
              <strong> collusion is inevitable.</strong> Without privacy
              guarantees, the utility of FHE is greatly diminished.
              <br /> <br /> Nebula Systems is building a new suite of
              cryptographic primitives that removes this risk entirely by
              allowing deterministic conditional decryption of arbitrary inputs
              with probabilistic security of the global decryption key. In the
              coming months, we will publish our work and open source our
              solution.
            </Typography>

            {/* Action buttons */}
            <Stack direction="row" spacing={2}>
              <Button
                onClick={handleNavigationDEX}
                variant="contained"
                sx={{
                  fontFamily: "Electrolize, Arial, sans-serif",
                  fontSize: { xs: "16px", sm: "20px", md: "20px", lg: "20px" },
                  height: { xs: "44px", sm: "52px", md: "52px", lg: "52px" },
                  color: "white",
                  backgroundColor: "#1B1B1B",
                  border: "1px solid",
                  borderColor: "#494949",
                  "&:hover": {
                    backgroundColor: "rgba(27, 27, 27, 0.6)", // Change opacity on hover
                    color: "rgba(255, 255, 255, 0.8)", // Change text color opacity on hover
                  },
                }}
              >
                read about DEXs
              </Button>

              <Button
                onClick={handleContact}
                variant="contained"
                endIcon={<ArrowOutward />}
                sx={{
                  fontFamily: "Electrolize, Arial, sans-serif",
                  fontSize: { xs: "16px", sm: "20px", md: "20px", lg: "20px" },
                  height: { xs: "44px", sm: "52px", md: "52px", lg: "52px" },
                  color: "white",
                  backgroundColor: "black",
                  border: "1px solid",
                  borderColor: "#494949",
                  "&:hover": {
                    backgroundColor: "black",
                    color: "rgba(255, 255, 255, 0.8)", // Change text color opacity on hover
                    borderColor: "rgba(73, 73, 73, 0.8)", // Change border color opacity on hover
                  },
                }}
              >
                contact us
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};

export default FHE;
